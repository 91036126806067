import {
  Card,
  CardBody,
  CardHeader,
  Heading,
  Link,
  Text,
} from '@chakra-ui/react';
import NextLink from 'next/link';
import FragmentLinkTarget from '../fragment-link';

export default function Post({
  children,
  title,
  id,
  date,
}: {
  id: string;
  title: string;
  date: string;
  children: React.ReactNode;
}) {
  return (
    <Card>
      <CardHeader>
        <FragmentLinkTarget id={id} />
        <Heading size="lg">
          <Link as={NextLink} href={`#${id}`}>
            {title}
          </Link>
        </Heading>
        <Text>{date}</Text>
      </CardHeader>
      <CardBody className="paragraph">{children}</CardBody>
    </Card>
  );
}
