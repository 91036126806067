'use client';
import { useLocalStorage } from './local-storage';

export function useFocusPlayer() {
  const [focusPlayer, setFocusPlayer] = useLocalStorage<string | null>(
    'focusPlayer',
    null,
    true
  );

  return { focusPlayer, setFocusPlayer };
}
